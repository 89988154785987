import React, {Component} from 'react';

const Footer =()=>{
return(
    <footer id="footer" className="footer">
    <div className="copyright">
      &copy; Copyright <strong><span>Admin</span></strong>. All Rights Reserved
    </div>
    <div className="credits">
    
      Designed by <a href="http://Loopsup.com/">Chris Tyagi</a>
    </div>
  </footer>
);
}
export default Footer;