import './App.css';
import Header from './component/Header';
import Sidebar from './component/Sidebar';
import Footer from './component/Footer';
import Login from './component/Login';
import Register from './component/Register';
import Slider from './component/slider';
import Content from './component/content';
import Dashboard from './component/Dashboard';
import Addcontent from './component/AddContent';
import UpdateContent from './component/UpdateContent';
import PicGallry from './component/PicGallary';
import Enquiry from './component/Enq';
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div>
      <Header />
      <Sidebar />
      <Routes>
        <Route path='Login' element={<Login />}></Route>
        <Route path='Register' element={<Register />}></Route>
        <Route path='Slider' element={<Slider />}></Route>
        <Route path='Content' element={<Content />}></Route>
        <Route path='/' element={<Dashboard />}></Route>
        <Route path='Addcontent' element={<Addcontent/>}></Route>
        <Route path='UpdateContent/:id' element={<UpdateContent/>}></Route>
        <Route path ="PicGallary" element={<PicGallry/>}></Route>
        <Route path ="Enquiry" element={<Enquiry/>}></Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
