import  React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {

    const navigate = useNavigate();
   // const [authenticated, setauthenticated] = useState(false);
    useEffect(() => {        

        moveto();
       
    }, []);

    const moveto =()=>{
        const loggedInUser = localStorage.getItem("authenticated");
        if(!loggedInUser)
        {
            navigate("Login");
        }
    }
    
        return (
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>

                <section class="section dashboard">
                    <div class="row">


                        <div class="col-lg-12">
                            <div class="row">


                                <div class="col-xxl-4 col-md-6">
                                    <div class="card info-card sales-card">



                                        <div class="card-body">
                                            <h5 class="card-title">Slider </h5>

                                            <div class="d-flex align-items-center">
                                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-box-arrow-in-right"></i>
                                                </div>
                                                <div class="ps-3">
                                                    <h6>145</h6>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-xxl-4 col-md-6">
                                    <div class="card info-card revenue-card">



                                        <div class="card-body">
                                            <h5 class="card-title">Enquiry </h5>

                                            <div class="d-flex align-items-center">
                                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-chat-dots"></i>
                                                </div>
                                                <div class="ps-3">
                                                    <h6>30</h6>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-12">

                                    <div class="card info-card customers-card">


                                        <div class="card-body">
                                            <h5 class="card-title">All Data <span>| Content</span></h5>

                                            <div class="d-flex align-items-center">
                                                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-card-checklist"></i>
                                                </div>
                                                <div class="ps-3">
                                                    <h6>50</h6>


                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }


export default Dashboard;