import React from "react";
import Uploadimg from "./uploadimg";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PicGallry=()=>{

    const [slider, setSlider] = useState();
    const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    const [data, setData] = useState('');
  
    // Callback function to update data
    const updateData = newData => {
      setData(newData);
      forceUpdate()
    };
  
    useEffect(() => {
      const url = 'https://sbvmapi.uniservedata.in/api/gallary';
      showSlider(url);
    }, [comUpdate]);
  
    const showSlider = async (url) => {
  
      const res = await fetch(url);
      const data = await res.json();
      console.log(data.gallary_data);
      setSlider(data.gallary_data);
    }
  
    const deleteData = async (id) => {
  
      const url = "https://sbvmapi.uniservedata.in/api/gallary/delete/" + id;
  
      const res = await fetch(url, {
        method: "delete"
      })
  
      const data = await res;
      if (data.status == "200") {
        toast.success("Data Deleted Successfull", {
          position: "top-right",
          theme: "colored",
        });
      }
      else {
        toast.error("Process Failed", {
          position: "top-right",
          theme: "colored",
        });
      }
      forceUpdate()
    }

    return (
        <main id="main" className="main">
    
    
          <div className="pagetitle">
            <h1>Pic Gallary Tables</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item">Pic Gallary Table</li>
    
              </ol>
            </nav>
          </div>
    
    
          <section className="section">
            < div className="row col-lg-12 col-md-12 ">
              <div className="row col-lg-7 col-md-7 ">
    
    
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Pic Gallary Data</h5>
    
    
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">img</th>
    
                          <th scope="col">Add Date</th>
    
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
    
                          slider && slider.map((slide, i) => (
                            <tr key={slide._id}>
                              <th scope="row">{i + 1}</th>
                              <td><img src={slide.img} /></td>
                              <td>{slide.AddDate}</td>
                              <td><button class="btn btn-primary w-100" onClick={() => { deleteData(slide._id) }} type="submit">Delete</button></td>
                            </tr>
                          ))}
    
                      </tbody>
                    </table>
    
                  </div>
                </div>
              </div>
    
              <div className="row col-lg-4 col-md-4 " style={{ "margin-left": "50px" }}>
                <Uploadimg  onUpdate={updateData} />
              </div>
            </div>
          </section>
          <ToastContainer />
        </main>
      );
    
}

export default PicGallry;